@media screen and (max-width: 640px) {
  .pfDetails {
    padding: 5vh 6vw;

    .pfDetails__carousel {
      padding-bottom: 2vh;
    }
    &__main {
      padding: 2vh 0;
    }
  }
}

@media screen and (min-width: 640px) {
  .pfDetails {
    padding: 4vh 6vw 2vh;

    .pfDetails__carousel {
      padding-bottom: 4rem;
    }
    &__main {
      padding: 4rem 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .pfDetails {
    padding: 2rem 6vw 2vh;

    .pfDetails__carousel {
      padding-bottom: 1rem;
    }
    &__main {
      padding: 1rem 0;
    }
  }
}
