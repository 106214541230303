@media screen and (max-width: 640px) {
  .services {
    padding: 10vh 6vw;

    &__main {
      padding: 6vh 0;
    }
  }
}

@media screen and (min-width: 640px) {
  .services {
    padding: 6vh 6vw 2vh;

    &__main {
      padding: 7vh 0 4vh;
    }
  }
}

@media screen and (min-width: 1024px) {
  .services {
    padding: 6vh 6vw 2vh;

    &__main {
      padding: 8vh 0 4vh;
    }
  }
}
