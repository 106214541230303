@media screen and (max-width: 640px) {
  .testiCard {
    flex-shrink: 0;
    width: 100%;
    height: 24rem;
    padding: 2rem 2rem 1rem 2rem;
    margin-right: 12vw;
    overflow: hidden;

    img {
      width: 35%;
      height: auto;
      border-radius: 50%;
    }
  }

  .serviceCard {
    flex-shrink: 0;
    width: 100%;
    height: 24rem;
    padding: 2rem;
    overflow: hidden;
  }

  .portfolioCard {
    flex-shrink: 0;
    width: 100%;
    height: 26rem;
    padding: 1.5rem;
    overflow: hidden;

    &__image {
      display: flex;
      width: 100%;
      max-height: 11rem;
      overflow: hidden;

      img {
        width: auto;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .testiCard {
    flex-shrink: 0;
    width: 100%;
    height: 30rem;
    padding: 2rem 2rem 1rem 2rem;
    margin-right: 32vw;
    overflow: hidden;

    img {
      width: 35%;
      height: auto;
      border-radius: 50%;
    }
  }

  .serviceCard {
    flex-shrink: 0;
    width: 80%;
    height: 30rem;
    padding: 2rem;
    overflow: hidden;
  }

  .portfolioCard {
    flex-shrink: 0;
    width: 80%;
    height: 39rem;
    padding: 2.5rem;
    overflow: hidden;

    &__image {
      display: flex;
      width: 100%;
      max-height: 20rem;
      overflow: hidden;

      img {
        width: auto;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .testiCard {
    flex-shrink: 0;
    width: 25vw;
    height: 24rem;
    padding: 2rem 2rem 1rem 2rem;
    margin-right: 2.5vw;
    overflow: hidden;

    img {
      width: 45%;
      height: auto;
      border-radius: 50%;
    }
  }

  .serviceCard {
    width: 26vw;
    height: 26vw;
    margin-bottom: 4.3vw;
  }

  .portfolioCard {
    width: 26vw;
    height: 33.5vw;
    padding: 2rem;
    margin-bottom: 4.3vw;

    &__image {
      max-height: 13vw;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    &__image:hover {
      transform: scale(1.08);
    }
  }
}

@media screen and (min-width: 1280px) {
  .portfolioCard {
    width: 26vw;
    height: 31vw;
    padding: 2rem;
    margin-bottom: 4.3vw;

    &__image {
      max-height: 13vw;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    &__image:hover {
      transform: scale(1.08);
    }
  }
}

@media screen and (min-width: 1536px) {
  .testiCard {
    height: calc(28rem + 2.5vw);
  }

  .portfolioCard {
    &__image {
      max-height: 14vw;
    }
    &__image:hover {
      transform: scale(1.05);
    }
  }
}
