.navbar {
  position: fixed;
  width: 100%;
  height: 9vh;
  background-color: var(--color-black);
  z-index: 100;
}

@media screen and (max-width: 640px) {
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5vw;

    &__logo {
      width: auto;
      height: 4.4vh;

      img {
        width: auto;
        height: 4.4vh;
      }
    }

    &__list {
      .icon-menu {
        color: var(--color-light);
        cursor: pointer;
      }
    }

    &__offcanvas {
      transition: all 350ms cubic-bezier(0.4, 0.2, 0, 1);
      position: absolute;
      top: 0;
      height: 100vh;
      width: 50vw;
      background-color: var(--color-black-light);

      &-header {
        height: 9vh;
        padding: 0 5vw;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .icon-close {
          color: var(--color-light);
          cursor: pointer;
        }
      }
    }

    .offcanvas-show {
      right: 0;
    }

    .offcanvas-hidden {
      right: -50vw;
    }
  }
}

@media screen and (min-width: 640px) {
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5vw;

    &__logo {
      width: auto;
      height: 5vh;

      img {
        width: auto;
        height: 5vh;
      }
    }

    &__list {
      .icon-menu {
        color: var(--color-light);
        cursor: pointer;
      }
    }

    &__offcanvas {
      transition: all 350ms cubic-bezier(0.4, 0.2, 0, 1);
      position: absolute;
      top: 0;
      height: 100vh;
      width: 40vw;
      background-color: var(--color-black-light);

      &-header {
        height: 9vh;
        padding: 0 5vw;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .icon-close {
          color: var(--color-light);
          cursor: pointer;
        }
      }
    }

    .offcanvas-show {
      right: 0;
    }

    .offcanvas-hidden {
      right: -40vw;
    }
  }
}

@media screen and (min-width: 1024px) {
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5vw;

    &__logo {
      width: auto;
      height: 5.5vh;

      img {
        width: auto;
        height: 5.5vh;
      }
    }
  }
}

@media screen and (min-width: 1536px) {
  .navbar {
    &__logo {
      height: 5.8vh;

      img {
        height: 5.8vh;
      }
    }
  }
}
