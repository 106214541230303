@media screen and (max-width: 640px) {
  .someWork {
    width: auto;
    margin: 6vh 0;
    padding: 6vh 6vw;
    overflow: hidden;
    background-color: var(--color-gray-dark);

    &__slide {
      position: relative;
      max-width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      transition: all 350ms cubic-bezier(0.4, 0.2, 0, 1);

      .workCard {
        flex-shrink: 0;
        width: 88vw;
        height: 88vw;
        margin-right: 12vw;
        overflow: hidden;
      }
    }

    &__btn {
      .icon-prev,
      .icon-next {
        transition: all 350ms cubic-bezier(0.4, 0.2, 0, 1);
        color: var(--color-light);
        font-size: 1.8rem;
      }

      .icon-disabled {
        color: var(--color-gray-light);
      }
    }

    .slide-1 {
      margin-left: 0;
    }

    .slide-2 {
      margin-left: -100vw;
    }

    .slide-3 {
      margin-left: -200vw;
    }

    .slide-4 {
      margin-left: -300vw;
    }
  }
}

@media screen and (min-width: 640px) {
  .someWork {
    width: auto;
    margin: 6vh 0;
    padding: 6vh 6vw;
    overflow: hidden;
    background-color: var(--color-gray-dark);

    &__slide {
      position: relative;
      max-width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      padding-left: 5vw;
      padding-right: 5vw;
      transition: all 350ms cubic-bezier(0.4, 0.2, 0, 1);

      .workCard {
        flex-shrink: 0;
        width: 78vw;
        height: 78vw;
        margin-right: 22vw;
        overflow: hidden;
      }
    }

    &__btn {
      .icon-prev,
      .icon-next {
        transition: all 350ms cubic-bezier(0.4, 0.2, 0, 1);
        color: var(--color-light);
        font-size: 2.6rem;
      }

      .icon-disabled {
        color: var(--color-gray-light);
      }
    }

    .slide-1 {
      margin-left: 0;
    }

    .slide-2 {
      margin-left: -100vw;
    }

    .slide-3 {
      margin-left: -200vw;
    }

    .slide-4 {
      margin-left: -300vw;
    }
  }
}

@media screen and (min-width: 1024px) {
  .someWork {
    width: auto;
    margin: 6vh 0;
    padding: 10vh 6vw;
    overflow: hidden;
    background-color: var(--color-gray-dark);
    position: relative;

    &__slide {
      position: relative;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 10vh;
      transition: all 350ms cubic-bezier(0.4, 0.2, 0, 1);

      .workCard:first-child,
      .workCard:last-child {
        flex-shrink: 0;
        width: 28vw;
        height: 28vw;
        margin-right: 0;
        overflow: hidden;

        img {
          object-fit: cover;
          object-position: center;
        }
      }

      .workCard {
        flex-shrink: 0;
        width: 60%;
        height: 28vw;
        margin-right: 0;
        overflow: hidden;

        .workCard-link {
          transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
          overflow: hidden;
        }

        .workCard-link:hover {
          transform: scale(1.02);
          cursor: pointer;
        }
      }
    }

    .slide-1 {
      margin-left: 0;
    }

    .square__image-1 {
      position: absolute;
      width: auto;
      height: 27vh;
      left: -7.5vw;
      z-index: 0;
      top: 26%;
      transform: translateY(-26%);
      -webkit-transform: translateY(-26%);
      -moz-transform: translateY(-26%);
      -ms-transform: translateY(-26%);
      -o-transform: translateY(-26%);

      img {
        width: auto;
        height: 27vh;
      }
    }

    .square__image-2 {
      position: absolute;
      width: auto;
      height: 27vh;
      right: -7.5vw;
      z-index: 0;
      top: 74%;
      transform: translateY(-74%);
      -webkit-transform: translateY(-74%);
      -moz-transform: translateY(-74%);
      -ms-transform: translateY(-74%);
      -o-transform: translateY(-74%);

      img {
        width: auto;
        height: 27vh;
      }
    }
  }
}

@media screen and (min-width: 1536px) {
  .someWork {
    padding: 10vh 8vw;

    &__slide {
      .workCard:first-child,
      .workCard:last-child {
        width: 26vw;
        height: 26vw;
      }

      .workCard {
        width: 60%;
        height: 26vw;
      }
    }
  }
}
