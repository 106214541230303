@media screen and (max-width: 640px) {
  .aboutUs {
    padding: 10vh 6vw;

    &__image {
      width: 100%;
      height: auto;
      padding-top: 2.2rem;

      img {
        width: 100%;
        height: auto;
        border-radius: 6px;
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .aboutUs {
    padding: 10vh 6vw;

    &__image {
      height: auto;
      padding-top: 2.8rem;

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .aboutUs {
    padding: 10vh 6vw;
    position: relative;

    &__image {
      height: auto;
      padding-top: 0;

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      img:hover {
        transform: scale(1.05);
      }
    }

    .square__image {
      position: absolute;
      width: auto;
      height: 27vh;
      left: -7.5vw;
      z-index: 0;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);

      img {
        width: auto;
        height: 27vh;
      }
    }
  }
}

@media screen and (min-width: 1536px) {
  .aboutUs {
    padding: 10vh 6vw;

    &__image {
      height: auto;
      padding-top: 0;

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      img:hover {
        transform: scale(1.05);
      }
    }

    .square__image {
      left: -8vw;
    }
  }
}
