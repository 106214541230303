@media screen and (max-width: 640px) {
  .contact {
    padding: 10vh 6vw;

    &__header {
      width: 100%;
    }

    &__main {
      padding: 0;
      width: 100%;

      &-maps {
        width: 100%;

        iframe {
          width: 100%;
          height: 30vh;
          border: 0;
          border-radius: 6px;
        }
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .contact {
    padding: 6vh 6vw 4vh;

    &__header {
      width: 100%;
    }

    &__main {
      padding: 0;
      width: 100%;

      &-maps {
        width: 100%;

        iframe {
          width: 100%;
          height: 30vh;
          border: 0;
          border-radius: 6px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .contact {
    padding: 6vh 6vw 4vh;

    &__header {
      width: 100%;
    }

    &__main {
      padding: 0;
      width: 100%;

      &-maps {
        width: 100%;

        iframe {
          width: 38vw;
          height: 22vw;
          border: 0;
          border-radius: 6px;
        }
      }
    }
  }
}

@media screen and (min-width: 1536px) {
  .contact {
    &__main {
      &-maps {
        iframe {
          width: 40vw;
          height: 24vw;
        }
      }
    }
  }
}
