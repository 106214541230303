@media screen and (max-width: 640px) {
  .notfound {
    padding: 6vh 6vw;

    &__main {
      height: auto;

      &-image {
        width: 100%;
        height: auto;

        img {
          margin: 0 auto;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .notfound {
    padding: 6vh 6vw 8vh;

    &__main {
      height: auto;

      &-image {
        width: 100%;
        height: auto;

        img {
          margin: 0 auto;
          width: 95%;
          height: auto;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .notfound {
    padding: 6vh 6vw;

    &__main {
      height: auto;

      &-image {
        width: 100%;
        height: auto;

        img {
          margin: 0 auto;
          width: 34%;
          height: auto;
        }
      }
    }
  }
}
