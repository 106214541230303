@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=Domine:wght@400;500;600;700&family=Quicksand:wght@400;500;600;700&display=swap');

:root {
  // --color-primary: #e99d0f;
  --color-primary: #fb7e39;
  --color-primary-light: #ffd382;

  --color-gray: #31414b;
  --color-gray-dark: #222d34;
  --color-gray-light: #475a65;
  --color-gray-input: #839198;
  --color-gray-surface: #aeaeae;

  --color-black: #13191d;
  --color-black-dark: #090d0f;
  --color-black-light: #182025;

  --color-light: #ffffff;

  --font-sans: 'Quicksand', sans-serif;
  --font-serif: 'Domine', serif;
  --font-display: 'Calistoga', cursive;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration-style: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input::placeholder,
textarea::placeholder {
  color: var(--color-gray-input);
}

button,
a {
  cursor: pointer;
}
